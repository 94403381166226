export const lightTheme = {
	body: "#212529",
	primary: "#146CDA",
	secondary: "#FFA500",
	primary__dark: "#0562D6",
	white: "#FFFFFF",
};

export const DarkTheme = {
	body: "#000000",
	text: "#FCF6F4",
	fontFamily: "'Manrope', sans-serif",
	textRgba: "252, 246, 244",
	bodyRgba: "0,0,0",
};
