export const products = [
      {
            id: 1,
            title: 'Gutter Profile',
            price: '490',
            image: '/images/products/bgg.jpg'
      },
      {
            id: 2,
            title: 'Jessica',
            price: '4,920',
            image: '/images/products/JESSICA-1.png'
      },
      {
            id: 3,
            title: 'OAK 3S',
            price: '170',
            image: '/images/products/505813000_OAK-3S.jpg'
      },
      {
            id: 4,
            title: 'Marine Green',
            price: '115',
            image: '/images/products/marine-green.jpg'
      },
      {
            id: 5,
            title: 'Outerdoor Hot Tub Spa',
            price: '9,67,000',
            image: '/images/products/SR830A.jpg'
      },
      {
            id: 5,
            title: 'SS Floor Drain with Cover Plate',
            price: '1,350',
            image: '/images/products/Floor-Drain-with-cover-plate-stainless.png'
      },
      {
            id: 6,
            title: 'Corrugrated cladding 16mm thick',
            price: '385',
            image: '/images/products/WPC-Wall-Panel.jpg'
      },
      {
            id: 7,
            title: 'Liquid Soap Dispenser',
            price: '1,200',
            image: '/images/products/ss-304-liquid-soap-dispenser.png'
      }
];