import styled from "styled-components";

export const AccountContainer = styled.div`
      background-color: #136BDA;
      padding: 20px;
      border-radius: 8px;

      h2 {
            font-size: 18px;
            text-align: center;
            margin-bottom: 5px;
            color: ${props => props.theme.white};
      }

      p {
            text-align: center;
            margin-bottom: 15px;
            color: ${props => props.theme.white};
      }
`;

export const LoginButton = styled.div`
      cursor: pointer;
      width: 100%;
      font-size: 16px;
      border-radius: 8px;
      padding: 2px 0;
      text-align: center;
      color: ${props => props.color ? props.theme.white : props.theme.secondary};
      background-color: ${props => props.color ? props.color : props.theme.white};
      margin-bottom: 8px;
      
      &:last-child {
            margin-bottom: 0;
      }
`;

export const AccountSlider = styled.div`
      margin-top: 20px;
      img {
            height: 160px;
            width: 100%;
            border-radius: 8px;
      }
`;