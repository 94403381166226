import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { BrandBody, BrandContainer, BrandHeader } from '../BrandBox/styles';
import { FurnitureBanner, FurnitureImage } from './styles';

const FurnitureCollection = () => {
      return (
            <>
                  <Container fluid="lg">
                        <BrandContainer className='mt-0'>
                              <BrandHeader>
                                    <h2>Funiture Collection</h2>
                                    <a href="#">View More</a>
                              </BrandHeader>
                              <BrandBody className='m-0 pt-3'>
                                    <Row>
                                          <Col lg={6}>
                                                <FurnitureBanner height="494px">
                                                      <img src="/images/banners/living-furniture1.jpg" className='banner-image' alt="" />
                                                </FurnitureBanner>
                                          </Col>
                                          <Col lg={6}>
                                                <Row className='g-3'>
                                                      <Col lg={6}>
                                                            <FurnitureBanner>
                                                                  <FurnitureImage>
                                                                        <img src="/images/banners/beds-banner.jpg" alt="" />
                                                                  </FurnitureImage>
                                                                  <h3>Bedroom Furniture</h3>
                                                            </FurnitureBanner>
                                                      </Col>
                                                      <Col lg={6}>
                                                            <FurnitureBanner>
                                                                  <FurnitureImage>
                                                                        <img src="/images/banners/wfh-banner.jpg" alt="" />
                                                                  </FurnitureImage>
                                                                  <h3>Office Furniture</h3>
                                                            </FurnitureBanner>
                                                      </Col>
                                                      <Col lg={6}>
                                                            <FurnitureBanner>
                                                                  <FurnitureImage>
                                                                        <img src="/images/banners/wooden-sofa-banner.jpg" alt="" />
                                                                  </FurnitureImage>
                                                                  <h3>Wooden Sofas</h3>
                                                            </FurnitureBanner>
                                                      </Col>
                                                      <Col lg={6}>
                                                            <FurnitureBanner>
                                                                  <FurnitureImage>
                                                                        <img src="/images/banners/space-save-banner.jpg" alt="" />
                                                                  </FurnitureImage>
                                                                  <h3>Coffee Table</h3>
                                                            </FurnitureBanner>
                                                      </Col>
                                                </Row>
                                          </Col>
                                    </Row>
                              </BrandBody>
                        </BrandContainer>
                  </Container>
            </>
      )
}

export default FurnitureCollection;