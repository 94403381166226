import styled from "styled-components";

export const CategoryBoxContainer = styled.div`
      background-color: ${props => props.theme.white};
      padding: 20px;
      padding-bottom: 2rem;
      border-radius: 8px;
`;

export const CategoryBoxImage = styled.div`
      width: 100%;
      height: 240px;
      overflow: hidden;
      border-radius: 8px;
      
      img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            transition: 0.3s ease-in;
            transform: scale(1);
      }
`;

export const CategoryBoxContent = styled.div`
      cursor: pointer;

      h3 {
            font-size: 16px;
            margin-bottom: 0;
            margin-top: 10px;
            padding: 0 10px;
      }

      &:hover {
            ${CategoryBoxImage} {
                  img {
                        transform: scale(1.04);
                  }
            }
      }
`;



export const CategoryBoxTitle = styled.div`
      text-align: center;
      margin: 1rem 0 1rem;
      
      h2 {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 0px;
            color: #333333;
      }

      span {
            color: #646464;
      }
`;