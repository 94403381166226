import styled, { css } from 'styled-components';

export const FlexComponent = css`
      display: flex;
      align-items: center;
`;

export const HeaderContainer = styled.header``;
export const HeaderContainerBox = styled.div``;
export const TopHeader = styled.div.attrs(() => ({
      className: ' w-100'
}))`
      background: ${(props) => props.theme.primary__dark};
      .container-lg {
            ${FlexComponent};
            justify-content: space-between;
            padding: 10px 0px;
      }
`;
export const HeaderSocialLink = styled.div`
      ${FlexComponent};
      gap: 10px;
      a {
            color: ${(props) => props.theme.white};
            ${FlexComponent};
            text-decoration: none;
      }
`;
export const HeaderTopContainer = styled.div`
      ${FlexComponent}
      justify-content: space-between;
      gap: 10px;
`;
export const HeaderTopContent = styled.div`
      ${FlexComponent};
      gap: 10px;
      color: ${(props) => props.theme.white};
      a {
            ${FlexComponent};
            text-decoration: none;
            gap: 10px;
            color: ${(props) => props.theme.white};
            font-size: 14px;
      }
      svg {
            fill: ${(props) => props.theme.white};
            width: 15px;
            height: 15px;
      }

      i {
            font-size: 20px;
      }
`;
export const HeaderContent = styled.div`
      background: ${(props) => props.theme.primary};
      .container-lg {
            ${FlexComponent};
            justify-content: space-between;
            padding: 20px 0;
      }
`;
export const HeaderLogo = styled.div`
      display: flex;
      gap: 160px;
      align-items: center;

      img {
            width: 120px;
      }
`;

export const HeaderSearchBar = styled.div`
      form {
            ${FlexComponent};
            background: ${(props) => props.theme.white};

            input {
                  font-size: 14px;
                  color: #9f9f9f;
                  width: 320px;
            }
            button {
                  background: ${(props) => props.theme.secondary};
                  padding: 8px 16px;
            }
            svg {
                  width: 20px;
                  height: 20px;
                  fill: ${(props) => props.theme.white};
            }
            .form-control:focus {
                  background-color: var(--bs-body-bg);
                  border-color: transparent;
                  box-shadow: none;
            }
      }
`;
export const HeaderActions = styled.div`
      ${FlexComponent};
      gap: 10px;
      ${'' /* color: ${(props) => props.theme.secondary}; */}
      svg {
            width: 24px;
            height: 24px;
            fill: ${(props) => props.theme.white};
      }
`;
export const HeaderCount = styled.div`
      background: ${(props) => props.theme.white};
      border-radius: 50%;
      ${FlexComponent};
      display: inline-flex;
      justify-content: center;
      width: 20px;
      height: 20px;
      padding: 10px;
`;
export const HeaderWishList = styled.a``;
export const HeaderCart = styled.a``;
export const HeaderNav = styled.div``;
