import React from "react";
import { AccountSection, BannerRow, BannerSection, CategorySection, SliderSection } from "../../styles";
import { Col, Container, Row } from "react-bootstrap";
import BannerSlider from "../../../../components/home/BannerSlider";
import CategoryMenu from "../../../../components/home/CategoryMenu";
import { AccountContainer, AccountSlider, LoginButton } from "./styles";

const BannerBox = () => {
	return (
		<>
			<BannerSection>
				<Container fluid="lg">
					<BannerRow>
						<CategorySection>
							<CategoryMenu />
						</CategorySection>
						<SliderSection>
							<BannerSlider />
						</SliderSection>
						<AccountSection>
							<AccountContainer>
								<h2>Welcome~~</h2>
								<p>
									Sign in for the best experince
								</p>
								<LoginButton>
									Register
								</LoginButton>
								<LoginButton color={props => props.theme.secondary}>
									Login
								</LoginButton>
							</AccountContainer>
							<AccountSlider>
								<img src="/images/sliders/side-banner.avif" alt="" />
							</AccountSlider>
						</AccountSection>
					</BannerRow>
				</Container>
			</BannerSection>
		</>
	);
};

export default BannerBox;
