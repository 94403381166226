import React from "react";
import {
	CategoryMenuContainer,
	CategoryMenuTitle,
	CategoryMenuWrapper,
} from "./styles";
import { categories } from "../../../data/categoryList";
import CategoryMenuItem from "./CategoryMenuItem";

const CategoryMenu = () => {
	return (
		<>
			<CategoryMenuContainer>
				{/* <CategoryMenuTitle className="mb-2">
					<h2 className="mb-0 text-white">All Categories</h2>
				</CategoryMenuTitle> */}
				<CategoryMenuWrapper>
					<ul className="ps-0">
						{categories?.length &&
							categories.map((category, index) => (
								<li key={index}>
									<CategoryMenuItem title={category.title} />
								</li>
							))}
					</ul>
				</CategoryMenuWrapper>
			</CategoryMenuContainer>
		</>
	);
};

export default CategoryMenu;
