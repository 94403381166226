import styled from "styled-components";
import { Swiper } from "swiper/react";

export const BrandContainer = styled.div`
      background-color: ${props => props.theme.white};
      margin: 20px 0;
      padding: 2rem 20px;
      border-radius: 8px;
`;

export const BrandHeader = styled.div`
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 0;
            color: #333;
      }

      a {
            display: block;
            color: ${props => props.theme.white};
            background-color: #333;
            text-decoration: none;
            padding: 6px 15px 5px;
            font-size: 13px;
            border-radius: 15px;
      }
`;

export const BrandBody = styled.div`
      margin: 0 40px;
      padding: 40px 0 0;
      position: relative;
`;

export const BrandSlider = styled(Swiper)`
      position: static;

      .swiper-button-next, .swiper-button-prev {
            background-color: #333;
            padding: 20px;
            opacity: 0.3;
            transform: .2s ease-out;

            &:hover {
                  opacity: 1;
            }
      }
      
      .swiper-button-prev {
            border-radius: 0px 20px 20px 0px;
      }
      
      .swiper-button-next {
            border-radius: 20px 0 0 20px;
      }
      
      .swiper-button-prev:after, .swiper-button-next:after {
            font-size: 18px;
            font-weight: 600;
            color: white;
      }
      
      .swiper-button-next:after {
            margin-right: -10px;
      }

      .swiper-button-prev:after {
            margin-left: -10px;
      }

      .swiper-button-next {
            right: -60px;
            position: absolute;
      }

      .swiper-button-prev {
            left: -60px;
            position: absolute;
      }

      img {
            height: 100px;
            border: 1px solid #333;
            width: 100%;
            border-radius: 8px;
      }
`;