import styled from "styled-components";

export const FurnitureImage = styled.div`
      height: 212.5px;
      width: 100%;
      border-radius: 8px;
      overflow: hidden;

      img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            transition: 0.3s ease-in;
            transform: scale(1);
      }
`;

export const FurnitureBanner = styled.div`
      height: ${props => props.height};

      .banner-image {
            width: 100%;
            height: 100%;
            border-radius: 8px;
      }

      h3 {
            font-size: 16px;
            padding: 0 10px;
            margin-top: 10px;
            margin-bottom: 0;
      }

      &:hover {
            ${FurnitureImage} {
                  img {
                        transform: ${props => props.height? 'scale(1)' : "scale(1.04)"};
                  }
            }
      }
`;