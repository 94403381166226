import React from 'react';
import { FooterContainer, FooterContent, FooterContentItem, FooterCopyRight, FooterCopyrightContent, PaymentPartner } from './styles';
import { Container } from 'react-bootstrap';
import FooterItem from './FooterItem';

const Footer = () => {
      return (
            <>
                  <FooterContainer>
                        <Container fluid="lg">
                              <FooterContent>
                              <FooterContentItem>
                                          <h4>Our Company</h4>
                                          <ul>
                                                <FooterItem title="About Us" />
                                                <FooterItem title="Career" />
                                                <FooterItem title="Blog" />
                                          </ul>
                                    </FooterContentItem>
                                    <FooterContentItem>
                                          <h4>Useful Links</h4>
                                          <ul>
                                                <FooterItem title="Customer Stories" />
                                                <FooterItem title="Customer Enquiry" />
                                                <FooterItem title="Testimonials" />
                                                <FooterItem title="Buyer FAQs" />
                                          </ul>
                                    </FooterContentItem>
                                    <FooterContentItem>
                                          <h4>Show by Room</h4>
                                          <ul>
                                                <FooterItem title="Living Room" />
                                                <FooterItem title="Bedroom" />
                                                <FooterItem title="Dinning Room" />
                                                <FooterItem title="Kids Room" />
                                          </ul>
                                    </FooterContentItem>
                                    <FooterContentItem>
                                          <h4>Partners</h4>
                                          <ul>
                                                <FooterItem title="Become a Franchise" />
                                                <FooterItem title="Sell with us" />
                                                <FooterItem title="Design Partner" />
                                                <FooterItem title="Design Services" />
                                          </ul>
                                    </FooterContentItem>
                                    <FooterContentItem>
                                          <h4>Need Help</h4>
                                          <ul>
                                                <FooterItem title="Contact Us" />
                                                <FooterItem title="Help Center" />
                                                <FooterItem title="Refund & Return" />
                                                <FooterItem title="Trank your order" />
                                          </ul>
                                    </FooterContentItem>
                              </FooterContent>
                        </Container>
                  </FooterContainer>
                  <PaymentPartner>
                        <Container fluid="lg">
                              <ul>
                                    <li> <img src="/images/payments/pay_icon1.png" alt="" /> </li>
                                    <li> <img src="/images/payments/pay_icon2.png" alt="" /> </li>
                                    <li> <img src="/images/payments/pay_icon3.png" alt="" /> </li>
                                    <li> <img src="/images/payments/pay_icon6.png" alt="" /> </li>
                              </ul>
                        </Container>
                  </PaymentPartner>
                  <FooterCopyRight>
                        <Container fluid="lg">
                              <FooterCopyrightContent>
                                    <div>
                                          Copyright © 2023 <span>Home Depot Nepal Pvt. Ltd.</span> All right reserved.
                                    </div>
                                    <div>
                                          Powered by <a href="https://infinityinfosys.com">Infinity Infosys Pvt. Ltd.</a>
                                    </div>
                              </FooterCopyrightContent>
                        </Container>
                  </FooterCopyRight>
            </>
      );
};

export default Footer;
