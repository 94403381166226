export const categories = [
	{
		id: "1",
		title: "Flooring Solutions",
	},
	{
		id: "2",
		title: "Wall Solutions",
	},
	{
		id: "3",
		title: "Sanitary Ware",
	},
	{
		id: "4",
		title: "Bathroom Fitting",
	},
	{
		id: "5",
		title: "False Ceiling Solutions",
	},
	{
		id: "1",
		title: "Flooring Solutions",
	},
	{
		id: "2",
		title: "Wall Solutions",
	},
	{
		id: "3",
		title: "Sanitary Ware",
	},
	{
		id: "4",
		title: "Bathroom Fitting",
	},
	{
		id: "5",
		title: "False Ceiling Solutions",
	},
];
