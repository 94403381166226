import React from "react";
import {
	HeaderActions,
	HeaderCart,
	HeaderContainer,
	HeaderContent,
	HeaderCount,
	HeaderLogo,
	HeaderNav,
	HeaderSearchBar,
	HeaderSocialLink,
	HeaderTopContainer,
	HeaderTopContent,
	HeaderWishList,
	TopHeader,
} from "./styles";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CartIcon, HeartIcon, PhoneIcon, SearchIcon } from "../../../icon";
import UserIcon from "../../../icon/UserIcon";

const Header = () => {
	return (
		<>
			<HeaderContainer>
				<TopHeader>
					<Container fluid="lg">
						<HeaderSocialLink>
							<Link to="/">
								<i className="bx bxl-facebook"></i>
							</Link>
							<Link to="/">
								<i className="bx bxl-instagram"></i>
							</Link>
							<Link to="/">
								<i className="bx bxl-youtube"></i>
							</Link>
							<Link to="/">
								<i className="bx bxl-linkedin"></i>
							</Link>
						</HeaderSocialLink>
						<HeaderTopContainer>
							<HeaderTopContent>
								<Link to="/">
									<PhoneIcon />
									9860566453
								</Link>
							</HeaderTopContent>
							<HeaderTopContent>
								<Link to="/">
									<i className='bx bx-help-circle'></i>
									Help Center
								</Link>
							</HeaderTopContent>
						</HeaderTopContainer>
					</Container>
				</TopHeader>
				<HeaderContent>
					<Container fluid="lg">
						<HeaderLogo>
							<img src="/images/logo.png" alt="home depot logo" />
							<HeaderSearchBar>
								<Form className="rounded">
									<Form.Control
										type="text"
										placeholder="Search in Home Depot"
										className="border-0 rounded-start ms-2"
									/>
									<Button className="rounded-0 border-0 rounded-end">
										<SearchIcon />
									</Button>
								</Form>
							</HeaderSearchBar>
						</HeaderLogo>
						<HeaderActions>
							<HeaderWishList style={{ marginRight: '3px' }}>
								<UserIcon />
							</HeaderWishList>
							<HeaderWishList>
								<HeartIcon />
								<sub className="mt-1">
									<HeaderCount>0</HeaderCount>
								</sub>
							</HeaderWishList>
							<HeaderCart>
								<CartIcon />
								<sub className="mt-1">
									<HeaderCount>0</HeaderCount>
								</sub>
							</HeaderCart>
						</HeaderActions>
					</Container>
				</HeaderContent>
				{/* <HeaderNav>
          <Container fluid="lg">
            <Row>
              <Col lg={12}>
                <nav>
                  <ul className=" ">
                    <li></li>
                  </ul>
                </nav>
              </Col>
            </Row>
          </Container>
        </HeaderNav> */}
			</HeaderContainer>
		</>
	);
};

export default Header;
