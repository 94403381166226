import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ProductContainer, ProductContent, ProductHeader, ProductImage, ProductItem, ProductSummary } from './styles';
import { products } from '../../../../data/productList';

const ProductBox = () => {
      return (
            <>
            <Container fluid="lg">
                  <ProductContainer>
                        <ProductHeader>
                              <h2>Product You May Like</h2>
                              <a href="#">View More</a>
                        </ProductHeader>
                        <ProductContent>
                              <Row className='g-3'>
                                    {products?.length > 0 && (
                                          products?.map((product, index) => (
                                          <Col lg={3}>
                                                <ProductItem>
                                                      <ProductImage>
                                                            <img src={product.image} alt={product.title} />
                                                      </ProductImage>
                                                      <ProductSummary>
                                                            <p>{product.title}</p>
                                                            <span>From Rs. {product.price}</span>
                                                      </ProductSummary>
                                                </ProductItem>
                                          </Col>
                                          ))
                                    )}
                                    
                              </Row>
                        </ProductContent>
                  </ProductContainer>
            </Container>
            </>
      )
}

export default ProductBox;