import React from "react";

const PhoneIcon = () => {
	return (
		<>
			<i className='bx bx-phone' ></i>
		</>
	);
};

export default PhoneIcon;
