import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { BrandBody, BrandContainer, BrandHeader, BrandSlider } from './styles';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

// import required modules
import {  Navigation } from 'swiper/modules';

const BrandBox = () => {
      return (
            <>
                  <Container fluid="lg">
                        <Row>
                              <Col lg={6}>
                                    <BrandContainer>
                                          <BrandHeader>
                                                <h2>Trending Brands</h2>
                                                <a href="#">View More</a>
                                          </BrandHeader>
                                          <BrandBody>
                                                <BrandSlider
                                                      spaceBetween={10}
                                                      slidesPerView={4}
                                                      navigation={true}
                                                      modules={[
                                                            Navigation,
                                                      ]}
                                                      className="mySwiper"
                                                >

                                                      <SwiperSlide>
                                                            <img src="/images/brands/Nitco-Tile.jpg" alt="" />
                                                      </SwiperSlide>
                                                      <SwiperSlide>
                                                            <img src="/images/brands/innova-endless-innovation.jpg" alt="" />
                                                      </SwiperSlide>
                                                      <SwiperSlide>
                                                            <img src="/images/brands/Gres-Aragon.jpg" alt="" />
                                                      </SwiperSlide>
                                                      <SwiperSlide>
                                                           <img src="/images/brands/Nexo-Tile.jpg" alt="" />
                                                      </SwiperSlide>
                                                      <SwiperSlide>
                                                            <img src="/images/brands/Rak.jpg" alt="" />
                                                      </SwiperSlide>
                                                </BrandSlider>
                                          </BrandBody>
                                          
                                    </BrandContainer>
                              </Col>
                              <Col lg={6}>
                                    <BrandContainer>
                                          <BrandHeader>
                                                <h2>Trending Category</h2>
                                                <a href="#">View More</a>
                                          </BrandHeader>
                                          <BrandBody>
                                                <BrandSlider
                                                      spaceBetween={10}
                                                      slidesPerView={4}
                                                      navigation={true}
                                                      modules={[
                                                            Navigation,
                                                      ]}
                                                      className="mySwiper"
                                                >

                                                      <SwiperSlide>
                                                            <img className='border-0' src="/images/categories-slider/1.webp" alt="" />
                                                      </SwiperSlide>
                                                      <SwiperSlide>
                                                            <img className='border-0' src="/images/categories-slider/2.webp" alt="" />
                                                      </SwiperSlide>
                                                      <SwiperSlide>
                                                            <img className='border-0' src="/images/categories-slider/3.webp" alt="" />
                                                      </SwiperSlide>
                                                      <SwiperSlide>
                                                           <img className='border-0' src="/images/categories-slider/4.webp" alt="" />
                                                      </SwiperSlide>
                                                      <SwiperSlide>
                                                            <img className='border-0' src="/images/categories-slider/5.webp" alt="" />
                                                      </SwiperSlide>
                                                </BrandSlider>
                                          </BrandBody>
                                          
                                    </BrandContainer>
                              </Col>
                        </Row>
                  </Container>
            </>
      );
};

export default BrandBox;
