import React from "react";
import { Link } from "react-router-dom";
import { RightIcon } from "../../../icon";

const CategoryMenuItem = ({ title }) => {
	return (
		<>
			<Link to="/" className="text-decoration-none">
				{title}
				<RightIcon />
			</Link>
		</>
	);
};

export default CategoryMenuItem;
