import styled from "styled-components";
import { FlexComponent } from "../../../frontend/layouts/header/styles";

export const CategoryMenuContainer = styled.div`
	background: ${(props) => props.theme.white};
	border-radius: 8px;
`;
export const CategoryMenuTitle = styled.div`
	background: ${(props) => props.theme.primary};
	padding: 15px 20px;
	h2 {
		text-transform: capitalize;
		font-size: 17px;
	}
`;
export const CategoryMenuWrapper = styled.div`
	padding: 10px 0;

	ul {
		margin-bottom: 0;

		li {
			list-style-type: none;
			margin: 0 0 5px;

			a {
				${FlexComponent}
				padding: 2px 20px;
				justify-content: space-between;
				color: #47494a;
				
				svg {
					transform: rotate(275deg);
					width: 15px;
					height: 15px;
					fill: #47494a;
				}
			}
		}
	}
`;
