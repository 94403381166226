import styled from "styled-components";

export const FooterContainer = styled.footer`
      background-color: #004F9A;
      padding: 50px 20px;
`;

export const FooterContent = styled.div`
      display: flex;
`;

export const FooterContentItem =styled.div`
      width: 20%;

      h4 {
            margin-bottom: 16px;
            font-size: 22px;
            color: ${props => props.theme.secondary};
      }

      ul {
            padding: 0;
            margin: 0;

            li {
                  list-style: none;

                  a {
                        text-decoration: none;
                        margin-bottom: 5px;
                        display: block;
                        color: ${props => props.theme.white};

                        &:hover {
                              color: ${props => props.theme.secondary};
                        }
                  }
            }
      }
`;

export const PaymentPartner = styled.div`
      background-color: white;
      padding: 10px 0;

      ul {
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 12px;

            li {
                  list-style: none;
            }
      }
`;

export const FooterCopyRight = styled.div`
      background-color: #004F9A;
      padding: 20px 0;
`;

export const FooterCopyrightContent = styled.div`
      display: flex;
      align-items: center;
      color: white;
      justify-content: space-between;

      a {
            text-decoration: none;
            color: ${props => props.theme.secondary};
      }
      
      span {
            color: ${props => props.theme.secondary};
      }
`;