import React from "react";
import { BannerBox, BrandBox, FurnitureCollection, OfferBox, ProductBox } from "./components";
import CategoryBox from "./components/CategoryBox";

const Home = () => {
	return (
		<>
			<BannerBox />
			<OfferBox />
			<CategoryBox />
			<BrandBox />
			<FurnitureCollection />
			<ProductBox />
		</>
	);
};

export default Home;
