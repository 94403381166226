import React from 'react';
import { BannerSliderContainer, BannerSliderItem } from './styles';

const BannerSlider = () => {
      return (
            <>
                  <BannerSliderContainer>
                        <BannerSliderItem>
                              <img
                                    src="/images/sliders/banner1.png"
                                    className="w-100"
                                    alt=""
                              />

                        </BannerSliderItem>
                  </BannerSliderContainer>
            </>
      );
};

export default BannerSlider;
