import styled from "styled-components";

export const BannerSliderContainer = styled.div`
      height: 100%;
`;

export const BannerSliderItem = styled.div`
      height: 100%;
      border-radius: 5px;

      img {
            height: 100%;
            border-radius: 5px;
      }
`;