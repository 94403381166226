import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from '../styles/components/globalstyle';
import './App.css';
import { lightTheme } from '../styles/components/themes';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from '../frontend/layouts/header';
import Footer from '../frontend/layouts/footer';
import React, { Suspense } from 'react';
import PublicRouter from '../router';

const App = () => {
    return (
        <>
            <GlobalStyle />
            <ThemeProvider theme={lightTheme}>
                <Router>
                    <Header />
                    <Suspense fallback="loading">
                        <PublicRouter />
                    </Suspense>
                    <Footer />
                </Router>
            </ThemeProvider>
        </>
    );
};
export default App;
