import styled from "styled-components";

export const ProductContainer = styled.div`
      padding-bottom: 20px;
`;

export const ProductHeader = styled.div`
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 0;
            color: #333;
      }

      a {
            display: block;
            color: ${props => props.theme.white};
            background-color: #333;
            text-decoration: none;
            padding: 6px 15px 5px;
            font-size: 13px;
            border-radius: 15px;
      }
`;

export const ProductContent = styled.div`
      margin-top: 10px;
`;

export const ProductImage = styled.div`
      height: 280px;
      width: 100%;
      border-radius: 5px;
      overflow: hidden;

      img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            object-fit: cover;
            transition: 0.3s ease-in;
            transform: scale(1);
      }
`;

export const ProductItem = styled.div`
      background-color: white;
      border-radius: 8px;
      cursor: pointer;
      padding: 10px;

      &:hover {
            ${ProductImage} {
                  img {
                        transform: scale(1.04);
                  }
            }
      }
`;

export const ProductSummary = styled.div`
      padding: 10px 12px;
      
      p {
            font-size: 18px;
            margin-bottom: 0;
      }

      span {
            font-size: 16px;
      }
`;