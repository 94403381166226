import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
@font-face{
    font-family: 'Acumin-Variable-Concept';
    src: url('/fonts/Acumin-Variable-Concept.ttf') format('truetype');
}
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Acumin-Variable-Concept';
}
`;
