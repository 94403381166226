import React from 'react';
import { OfferBanner } from './styles';
import { Container } from 'react-bootstrap';

const OfferBox = () => {
      return (
            <>
                  <OfferBanner>
                        <Container fluid="lg">
                              <img src="/images/banners/Home Depot Banner.png" alt="" />
                        </Container>
                  </OfferBanner>
            </>
      )
}

export default OfferBox;