import React from 'react';
import { Link } from 'react-router-dom';

const FooterItem = ({ title, link }) => {
      return (
            <>
                  <li>
                        <Link to={link ? link : '#'}>{title}</Link>
                  </li>
            </>
      );
};

export default FooterItem;
