import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { CategoryBoxContainer, CategoryBoxContent, CategoryBoxImage, CategoryBoxTitle } from './styles';

const CategoryBox = () => {
      return (
            <>
            <Container fluid="lg">
                  <CategoryBoxContainer>
                        <CategoryBoxTitle>
                              <h2>Top Picks For You</h2>
                              <span>Impressive Collection For Your Dream Home</span>
                        </CategoryBoxTitle>

                        <Row className='g-3'>
                              <Col lg={3}>
                                    <CategoryBoxContent>
                                          <CategoryBoxImage>
                                                <img src="/images/categories/floor-tiles.jpeg" alt="" />
                                          </CategoryBoxImage>
                                          <h3>Floor Tile</h3>
                                    </CategoryBoxContent>
                              </Col>
                              <Col lg={3}>
                                    <CategoryBoxContent>
                                          <CategoryBoxImage>
                                                <img src="/images/categories/cladding.jpeg" alt="" />
                                          </CategoryBoxImage>
                                          <h3>Cladding</h3>
                                    </CategoryBoxContent>
                              </Col>
                              <Col lg={3}>
                                    <CategoryBoxContent>
                                          <CategoryBoxImage>
                                                <img src="/images/categories/Roofing-Solutions.jpg" alt="" />
                                          </CategoryBoxImage>
                                          <h3>Roofing Solutions</h3>
                                    </CategoryBoxContent>
                              </Col>
                              <Col lg={3}>
                                    <CategoryBoxContent>
                                          <CategoryBoxImage>
                                                <img src="/images/categories/parquet-flooring.jpg" alt="" />
                                          </CategoryBoxImage>
                                          <h3>Parquet Flooring</h3>
                                    </CategoryBoxContent>
                              </Col>
                              <Col lg={3}>
                                    <CategoryBoxContent>
                                          <CategoryBoxImage>
                                                <img src="/images/categories/kitchen-assoceries.jpg" alt="" />
                                          </CategoryBoxImage>
                                          <h3>Kitchen Accessories</h3>
                                    </CategoryBoxContent>
                              </Col>
                              <Col lg={3}>
                                    <CategoryBoxContent>
                                          <CategoryBoxImage>
                                                <img src="/images/categories/sanitary ware.png" alt="" />
                                          </CategoryBoxImage>
                                          <h3>Waste Water Treatment Plant</h3>
                                    </CategoryBoxContent>
                              </Col>
                              <Col lg={3}>
                                    <CategoryBoxContent>
                                          <CategoryBoxImage>
                                                <img src="/images/categories/wolf-showers-main.png" alt="" />
                                          </CategoryBoxImage>
                                          <h3>Waste Water Treatment Plant</h3>
                                    </CategoryBoxContent>
                              </Col>
                              <Col lg={3}>
                                    <CategoryBoxContent>
                                          <CategoryBoxImage>
                                                <img src="/images/categories/Wastewater-Treatment-Plant.jpg" alt="" />
                                          </CategoryBoxImage>
                                          <h3>Waste Water Treatment Plant</h3>
                                    </CategoryBoxContent>
                              </Col>
                        </Row>
                  </CategoryBoxContainer>
            </Container>
            </>
      )
}

export default CategoryBox;