import styled from "styled-components";

export const BannerSection = styled.div`
	
	padding: 20px 0px;
`;

export const BannerRow = styled.div`
	display: flex;
	gap: 20px;
`;

export const CategorySection = styled.div`
	width: 20%;
`;

export const SliderSection = styled.div`
	width: 55%;
`;

export const AccountSection = styled.div`
	width: 25%;
`;
